<template>
  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <b-card>

        <b-row v-if="checkPermission('custom-forms', 'Update Item')">
          <b-col md=12>

            <div class="demo-inline-spacing float-right ">
              
                <b-button
                  variant="danger"
                  class="mt-0 mb-1"
                  @click="openLocations()"
                  v-if="disabledOptions == false"
                >
                <feather-icon
                    icon="TargetIcon"
                    class="mr-25"
                  />
                  <span>Locations</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-alert
          v-model="showDismissibleAlert"
          variant="danger"
          dismissible
          class="mb-1"
        >
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>

        <b-row>

          <b-col md="12">
              
              <h4 class="card-title float-left"> Meeting Room Defects</h4>
              
          </b-col>
          
        </b-row>

        <b-row>

          <b-col md="3">
              
              <b-form-group
                  label="Project Site"
                  class="required"

                >
                  <b-form-select v-model="site" @change="siteChange" :disabled="disabledOptions">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    
                    <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                    
                    
                  </b-form-select>
                    
                </b-form-group>
              
          </b-col>

          <b-col md="3">
              
              <b-form-group
                  label="Shift"
                  class="required"

                >
                  <b-form-select v-model="shift" @change="getLocationData()" :disabled="disabledOptions">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    
                    <b-form-select-option :value="shift._id" v-for="shift in shifts" :key="shift._id">{{shift.shift | capitalize}}</b-form-select-option>
                    
                    
                  </b-form-select>
                    
                </b-form-group>
              
          </b-col>

          <b-col md="3" >

            <b-form-group
              label="Date"
              class="required"

            > 
              <b-form-datepicker
              
                v-model="date"
                :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                locale="en"
                @input="getLocationData()"
                :disabled="disabledOptions"
              />

                
            </b-form-group>

          </b-col>

          <b-col md="3" >
            <b-form-group
              label="Checked By"
              class="required"

            >
              <b-form-input 
                autocomplete="off"
                placeholder="3 to 50 characters"
                v-model="checked_by"
                :disabled="disabledOptions"
              />
                
            </b-form-group>
          </b-col>

          

        </b-row>
        
    
    </b-card>
    
    <b-form @submit="formSubmit">
    
      
    <b-card no-body class="mb-1" v-for="(list,i) in checklistData" :key="i">
      <div v-if="site && shift != ''">
        <b-table
              responsive
              show-empty
              ref = "refUserListTable"
              class = "position-relative mrdTableFH fillReadOnly"
              primary-key = "id"
              empty-text = "No records found"
              :fields = "tableColumns"
              :items = "list.data"
          >

          <template slot="thead-top" slot-scope="data">
            <tr>
              
              <th colspan="2"> 
                <b-form-select v-model="list.building" @change="buildingChange(list.building,i)" :disabled="(checkdisabled() && (checklistData.length > 0))">

                  <b-form-select-option value="" disabled>Select Building</b-form-select-option>
                  
                  <b-form-select-option :value="building._id" v-for="building in buildings" :key="building._id" :disabled="buildingDisable(building._id)">{{building.name | capitalize}}</b-form-select-option>
                  
                  
                </b-form-select>
              </th>
              <th colspan="2" style="background-color: #DCDCDC;color:black;text-align:center;">Light Defects</th>
              <th colspan="3" style="background-color: #F3E5AB;color:black;text-align:center;">Meeting Rooms Chairs (Push Chairs to blue tape)</th>
              <th colspan="2" style="background-color: #f5c6cb; color:black;text-align:center;">Plastic Chairs / Sofa Chairs</th>
              <th colspan="2" style="background-color: #b8daff; color:black;text-align:center;">Projector</th>
              <th colspan="1" style="background-color: #FEFCFF; color:black;text-align:center;">Set</th>
              <!-- <th colspan="1" style="background-color: #EDE6D6;color:black;text-align:center;">Other Defects</th>
              <th colspan="1" style="background-color: #FCDFFF;color:black;text-align:center;">Crew Signature</th> -->
              
              <th colspan="2">
                <b-button
                  size="sm"
                  variant="danger"
                  class="mr-1 float-right"
                  @click="removeData(i)"
                  v-if="checklistData.length > 0 && checklistData[0].data.length > 0 && checklistData[i].db_building == false"
                  :disabled="checkdisabled()">
                 Remove
                </b-button>

                <b-button
                  size="sm"
                  variant="danger"
                  class="mr-1 float-right"
                  @click="removeDBBuilding(i,checklistData[i])"
                  v-if="checklistData.length > 0 && checklistData[0].data.length > 0 && checklistData[i].db_building == true"
                  :disabled="checkdisabled()">
                 Remove
                </b-button>

              </th>
            </tr>
          </template>

          <template #cell(level)="items">

              
              <p class="wordBreak">{{items.item.level}}</p>
         
          </template>

          <template #cell(meeting_room)="items">

            <!-- <b-form-input autocomplete="off" placeholder="" v-model="items.item.location" /> -->
            <p class="wordBreak">{{items.item.meeting_room}}</p>
         
          </template>

          <template #cell(light_defects)="items">


              <!-- <div class="demo-inline-spacing">
                <b-form-radio
                  class="mb-0 mt-1"
                  
                  :name="'some-radios' + i + '' + items.index"
                  value="yes"
                  v-model="items.item.light_defects"
                  @change="updateRadio(i,items.index)"
                >
                  Yes
                </b-form-radio>

                <b-form-radio
                  class="mb-0 mt-1"
                  
                  :name="'some-radios' + i+ '' + items.index"
                  value="no"
                  v-model="items.item.light_defects"
                  @change="updateRadio(i,items.index)"
                >
                  No
                </b-form-radio>

                <b-form-radio
                  class="mb-0 mt-1"
                  
                  :name="'some-radios' + i + '' + items.index"
                  value="na"
                  v-model="items.item.light_defects"
                  @change="updateRadio(i,items.index)"
                >
                  N/A
                </b-form-radio>

                <b-form-radio
                  class="mb-0 mt-1"
                  
                  :name="'some-radios' + i + '' + items.index"
                  value="custom"
                  v-model="items.item.light_defects"
                  @change="updateRadio(i,items.index)"
                >
                  Custom
                </b-form-radio>

                <b-form-input autocomplete="off" placeholder="" v-if="items.item.light_defects == 'custom'" v-model="items.item.light_defects_custom" />

                
              </div> -->
              
              <div :class="items.item.light_defects == 'custom' ? 'demo-inline-spacing mrdDemoInputInline' : 'demo-inline-spacing mrdDemoInline'">
                <b-form-checkbox
                  v-model="items.item.light_defects"
                  value="yes"
                  class="custom-control-danger"
                  @change="updateRadio(i,items.index)"
                  :disabled="items.item.light_defects_disable"
                >
                  Yes
                </b-form-checkbox>

                <b-form-checkbox
                  v-model="items.item.light_defects"
                  value="no"
                  class="custom-control-success"
                  @change="updateRadio(i,items.index)"
                  :disabled="items.item.light_defects_disable"
                >
                  No
                </b-form-checkbox>

                <b-form-checkbox
                  v-model="items.item.light_defects"
                  value="custom"
                  class="custom-control-warning"
                  @change="updateRadio(i,items.index)"
                  :disabled="items.item.light_defects_disable"
                >
                  Custom
                </b-form-checkbox>

                <feather-icon :icon="items.item.light_defects_disable == true ? 'EyeIcon' : 'EyeOffIcon'" class="mt-0" @click="disableLightDefects(items.index,i)"/>

                

              </div>
              <b-form-input autocomplete="off" class="mt-1" placeholder="" v-if="items.item.light_defects == 'custom'" v-model="items.item.light_defects_custom" />

              
         
          </template>

          <template #cell(light_defect_qty)="items">

              <b-form-input autocomplete="off" placeholder="" v-if="items.item.light_defects == 'yes'" v-model="items.item.light_defect_qty" />
          </template>

          <template #cell(mr_quantity)="items">

              <b-form-input autocomplete="off" placeholder="" v-model="items.item.mr_quantity" :readonly="items.item.mr_quantity_disable" @dblclick="disableFields(i,items.index,'mr_quantity')"/>
         
          </template>

          <template #cell(mr_yes)="items">

              <b-form-input autocomplete="off" placeholder="" v-model="items.item.mr_yes" :readonly="items.item.mr_yes_disable" @dblclick="disableFields(i,items.index,'mr_yes')"/>
         
          </template>

          <template #cell(mr_no)="items">

              <b-form-input autocomplete="off" placeholder="" v-model="items.item.mr_no" :readonly="items.item.mr_no_disable" @dblclick="disableFields(i,items.index,'mr_no')" />
         
          </template>

          <template #cell(plastic)="items">

              <b-form-input autocomplete="off" placeholder="" v-model="items.item.plastic" :readonly="items.item.plastic_disable" @dblclick="disableFields(i,items.index,'plastic')"/>
         
          </template>
          <template #cell(sofa)="items">

              <b-form-input autocomplete="off" placeholder="" v-model="items.item.sofa" :readonly="items.item.sofa_disable" @dblclick="disableFields(i,items.index,'sofa')"/>
         
          </template>
          <template #cell(projector_on)="items">

              
              <!-- <b-form-input autocomplete="off" placeholder="" v-model="items.item.projector_on" :readonly="items.item.projector_on_disable" @dblclick="disableFields(i,items.index,'projector_on')"/> -->
              <b-form-checkbox
              v-model="items.item.projector_on"
              value="yes"
              unchecked-value=""
              class="custom-control-success"
              @change="updateProjectorCheckbox($event,items.index,'on',i)"
              :disabled="items.item.projector_on_disable"
              
            />

            <feather-icon :icon="items.item.projector_on_disable == true ? 'EyeIcon' : 'EyeOffIcon'" class="mt-1" @click="disableCheckbox(items.index,'on',i)"/>

         
          </template>
          <template #cell(projector_off)="items">

            <b-form-checkbox
              v-model="items.item.projector_off"
              value="yes"
              unchecked-value=""
              class="custom-control-danger"
              @change="updateProjectorCheckbox($event,items.index,'off',i)"
              :disabled="items.item.projector_off_disable"
            />

            <feather-icon :icon="items.item.projector_off_disable == true ? 'EyeIcon' : 'EyeOffIcon'" class="mt-1" @click="disableCheckbox(items.index,'off',i)"/>
         
          </template>

          <template #cell(set_degree)="items">

            <b-form-checkbox
              v-model="items.item.set_degree"
              value="yes"
              unchecked-value=""
              class="custom-control-warning"
            />
         
          </template>

          <template #cell(other_defect)="items">

              <!-- <b-form-input autocomplete="off" placeholder="" v-model="items.item.other_defect" /> -->
              <b-form-checkbox
              v-model="items.item.other_defect"
              value="yes"
              unchecked-value=""
              class="custom-control-primary"
            />
         
          </template>

          <template #cell(remarks)="items">

              <b-form-input autocomplete="off" placeholder="" v-model="items.item.remarks" />
         
          </template>



        </b-table>
      </div>
    </b-card>

    <b-card class="mt-1" v-if="checklistData.length > 0 && checklistData[0].data.length > 0">
      

      <b-row>

        <b-col md="3">
        </b-col>

          <b-col md="3">

            <b-form-group
                label="Start Time"
                class="required"

              >
                <flat-pickr
                  v-model="startTime"
                  class="form-control"
                  :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
                />
                  
            </b-form-group>

            
          </b-col>

          <b-col md="3">

            <b-form-group
                label="End Time"
                class="required"

              >
                <flat-pickr
                  v-model="endTime"
                  class="form-control"
                  :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
                />
                  
            </b-form-group>

            
          </b-col>

          <b-col md="3">
          
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="mr-1 float-right mt-2"
              @click="addMore"
              v-if="site != ''"
              :disabled="checkdisabled()"
            >
              Add More
            </b-button>
          </b-col>

      </b-row>

      <b-row>
        
        <b-col cols="12" class="mb-2 mt-2"> 

            <div class="pb-2">
              
              <!-- <h4 class="text-center pb-2 mb-0"> Crew Signature  <span style="color:red">*</span></h4> -->
                    <b-form-group
                      label="Signature"
                      class="required" 
                      style="font-size:20px;color:white;text-align:center"
                    />

                <center>
                  
                  <!-- <b-media class="mb-2 mt-2" > -->
                      
                      <!-- <template #aside> -->
                      <div v-if="signature.image == null" @click="openSignature('supervisor')" style="width:250px">
                          <b-avatar ref="supervisorS" :src="doc_icon" variant="light-info" size="200px" rounded style="height: 200px; width: auto"/>
                      <!-- </template> -->
                      </div>

                      <div class="d-flex flex-wrap">
                          <input ref="supervisorSInput" type="file" class="d-none" @input="supervisorImageUpload" >
                      </div>

                  <!-- </b-media> -->

                  <img :src="signature.image" class="rounded" v-if="signature.image != null" height="180" width="200px" style="background: #ffffff;">
                  
                </center>
                <center>
                  
                    <b-button variant="danger" class="mt-1 ml-2" v-if="signature.image != null" @click="removeSign('supervisor')" :disabled="disabledOptions">
                          <feather-icon icon="XIcon" />
                    </b-button>
                </center>
                    
            </div>

        </b-col>
      </b-row>

      <b-row>
        <b-col>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class = "mr-1"
            variant="outline-secondary"
            @click="goBack()"
          >
            Cancel
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="mr-1"
            v-if="id != null && typeof $route.params.id == 'undefined'" 
            @click="discardForm()"
          >
            Discard Draft
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            type="submit"
            
            @click="status='draft'"
            v-if="typeof $route.params.id == 'undefined'"
          >
            {{ id == null ? 'Save as Draft' : 'Update Draft'}}
          </b-button>

          

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mr-1"
            type="submit"
            @click="status='completed'"
          >
            {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Save' }}
          </b-button>

          	<b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            v-if="typeof $route.params.id != 'undefined' && ledit == false"
            @click="openSignature('approver')"
          >
            {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Approve' }}
          </b-button>

          

        </b-col>
      </b-row>


    </b-card>

    </b-form>

    <b-modal
        id="signatureModel"
        ref="signatureModel"
        centered
        title="Signature"
        no-close-on-backdrop
        hide-footer
        @hide="hideSignature"
        @show="hideSignature"
      >
      
      <div v-if="show_default == true">
        <b-row>

          <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
            <center>
              <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
            </center>

          </b-col>
        </b-row>
          <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">

          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'administrator' ? false : true"
              :disabled-date="disabledDate"
              ></date-picker>


          </b-col>
            </b-row>

        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          <b-col md="12">
            <b-form-group
              label="Remarks"
              class=""
            >
              <b-form-textarea
              placeholder=""
              rows="3"
              v-model="approve_remark"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">

          <b-col md="6">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  @click="show_default = false"
                >
                <span class="text-nowrap">Add New Signature</span>
              </b-button>
            </b-col>

            <b-col md="6">
              <b-button
                  variant="warning"
                  class="mt-0 float-right w-100"
                  @click="saveDefault()"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>

      <div v-else>
        <b-row>
          <b-col md="12">
            <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
            />

          </b-col>
        </b-row>
          <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'administrator' ? false : true"
              :disabled-date="disabledDate"
              ></date-picker>


          </b-col>
        </b-row>

        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <b-form-group
                label="Remarks"
                class=""
              >
                <b-form-textarea
                  placeholder=""
                  rows="3"
                  v-model="approve_remark"
                />
              </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">

          <b-col md="3" v-if="typeof $route.params.id == 'undefined'">
            <b-button
                variant="primary"
                class="mt-0 w-100"
                @click="uploadSign()"
              >
              <span class="text-nowrap">Upload</span>
            </b-button>
          </b-col>

          <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  v-if="having_default == true"
                  @click="show_default = true"
                >
                <span class="text-nowrap">Default</span>
              </b-button>
            </b-col>


          <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

              <b-button
                  variant="danger"
                  class="mt-0 w-100"
                  @click="clearSignature"
                >
                <span class="text-nowrap">Clear</span>
              </b-button>
            </b-col>

            <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">
              <b-button
                  variant="warning"
                  class="mt-0 w-100"
                  @click="saveSignature"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>
      </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,BFormCheckbox,BFormDatepicker,BBreadcrumb,BFormRadio
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import 'viewerjs/dist/viewer.css'
import Datepicker from 'vuejs-datepicker';
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
var moment = require('moment-timezone');
import CrewSignature from './CrewSignature.vue';
import Bus from "../../../event-bus";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,Datepicker,BFormCheckbox,flatPickr,vSelect,BFormDatepicker,CrewSignature,BBreadcrumb,BFormRadio, DatePicker
  },  
  data() {
    return {
      //locations:[],

      date:moment().tz('Asia/Singapore').format('YYYY-MM-DD'),
      startTime:'',
      endTime:'',
      //site:this.$route.params.site_id,
      site:'',
      checked_by:this.$store.getters.currentUser.full_name,
      shift:'',
      building:'',
      buildings:[],
      sites:[],
      shifts:[],

      showDismissibleAlert:false,
      error_message:'',
      doc_icon: require('@/assets/images/doc.png'),
      
      id:null,
      checklistData:[],
      status:'draft',
      signature:{image:null,name:'',default:'no'},
      
      openedSignModel:null,

      supervisorSign : {
          image:null,
          name:'',
          type: 'supervisor',
          default:'no'
      },
      tempSign : {
          image:null,
          name:'',
      },
      show_default:false,
      having_default:false,
      disabledOptions:false,
      approve_signature_time:null,
      approve_remark:'',
      ledit : false,

      tableColumns:[
        { key: 'level', label: 'Level', sortable: false , thStyle:  {width: '8%'}},
        { key: 'meeting_room', label: 'Meeting Room', sortable: false , thStyle:  {width: '11%'}},
        { key: 'light_defects', label: '(No Defect - No Defect Indicate Number)', sortable: false , thStyle:  {width: '16%'}},
        { key: 'light_defect_qty', label: 'Defect Qty', sortable: false , thStyle:  {width: '6%'}},
        { key: 'mr_quantity', label: 'Qty', sortable: false , thStyle:  {width: '6%'}},
        { key: 'mr_yes', label: 'Yes', sortable: false , thStyle:  {width: '6%'}},
        { key: 'mr_no', label: 'No', sortable: false , thStyle:  {width: '6%'}},

        { key: 'plastic', label: 'Plastic', sortable: false , thStyle:  {width: '6%'}},
        { key: 'sofa', label: 'Sofa', sortable: false , thStyle:  {width: '6%'}},
        
        { key: 'projector_on', label: 'On', sortable: false , thStyle:  {width: '4%'}},
        { key: 'projector_off', label: 'Off', sortable: false , thStyle:  {width: '4%'}},
        
        { key: 'set_degree', label: '25 deg-C', sortable: false , thStyle:  {width: '5%'}},
        
        { key: 'other_defect', label: 'Other Defects', sortable: false , thStyle:  {width: '4%'}},
        
        { key: 'remarks', label: 'Remarks', sortable: false , thStyle:  {width: '11%'}},

      ],
      
    }

  },
  directives: {
    Ripple,
  },
  methods: {
    showAlert() {
            
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: this.error_message,
          showConfirmButton: false,
          timer: 3000
        })
    },

    
    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
             role:this.$store.getters.currentUser.role,
             //om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;

                if (this.sites.length == 1 && typeof this.$route.params.id == 'undefined') {
                            
                    this.site = this.sites[0]._id;
                    this.siteChange();
                }

                return this.sites;
            }
        });
    },
    formSubmit(e){
      e.preventDefault();

      if(this.status == 'draft'){
        this.actualForm();            
      } else {    
        this.decisionAlert('Are you sure want to submit this report ?')
        .then(result => {
          if (result.value) {
            this.actualForm();            
          }
        })
      }
    },
    actualForm(){
    
      return this.$store.dispatch(POST_API, {
        data:{
            date : this.date,
            site :this.site,
            checked_by : this.checked_by,
            shift : this.shift,
            checklistData : this.checklistData,
            status:this.status,
            signature:this.signature,
            id:this.id,
            startTime:this.startTime,
            endTime:this.endTime,
            form_id:this.$route.params.id,
            approve_signature: this.supervisorSign,
            approve_signature_time: this.approve_signature_time,
            approve_remark: this.approve_remark,
            role:this.$store.getters.currentUser.role,
        },
        api : '/api/submit-meeting-room-defects-checklist'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message        = this.$store.getters.getErrors;
              //this.showDismissibleAlert = true;

              //window.scrollTo(0,0);
              this.errorAlertCF()
          } else {
              this.showDismissibleAlert = false;

              var data = this.$store.getters.getResults.data;

              this.successAlert().then((result) => {

                //this.site = this.$route.params.site_id;

                if (this.sites.length == 1) {
                            
                    this.site = this.sites[0]._id;
            
                }else{

                    this.site = '';
                }
                this.siteChange();

                Bus.$emit('counter_update');
                
                if (data != null) {
                    window.open(data, '_blank');
                }
                localStorage.setItem('routeCFCheck','no');
                // this.$router.go(-1);
                if(this.$route.params.id){
                  this.$router.push({name:'meeting-room-defect-report'})
                }else{

                  this.$router.push({ name:'custom-forms' })
                }
                  
              });
          }
      });   
    },
    getLocationData(){
      localStorage.setItem('routeCFCheck','no');
      // this.shift != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.site != '' ? this.site : null,
             shift:this.shift != '' ? this.shift : null,
             date:this.date,
           },
           api: '/api/get-meeting-room-defect-data'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                this.id = data.id;
                this.checklistData = data.checkListData;
                this.signature = data.crew_signature;
                this.startTime = data.startTime;
                this.endTime = data.endTime;
                return this.checklistData;
            }
        });
    },
    getShiftData(){

      return this.$store.dispatch(POST_API, {
           data:{
             site:this.site != '' ? this.site : null,
           },
           api: '/api/site-shifts'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.shifts = data;
                return this.shifts;
            }
        });
    },
   
    siteChange(){
      this.site != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');
      
      this.checklistData = [];
      this.shifts = [];
      this.shift = '';

      if (this.site != '') {
        this.getShiftData();
        this.getBuildings();
        this.getLocationData();
        
        //localStorage.setItem('routeCFCheck','yes');
      }else{
        //localStorage.setItem('routeCFCheck','no');
      }
    },

    buildingChange(id,i){
      this.checklistData[0].building != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');

      if (id != '') {

        return this.$store.dispatch(POST_API, {
           data:{
             building:id,
             site:this.site
           },
           api: '/api/meeting-room-defect-locations-data'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;

                this.checklistData[i].data = data;
                return this.checklistData;
            }
        });


      }else{
        this.checklistData[i].data = [];
      }

    },
    addMore(){
      
      var order = 0;

      if (this.checklistData.length > 0) {
        var lastIndex = this.checklistData.length - 1;
        order = this.checklistData[lastIndex].order + 1;
      }

      var obj = {
          building:'',
          data : [],
          order:order,
          db_building:false,
      };

      this.checklistData.push(obj);
    },

    removeData(index){
      this.checklistData.splice(index,1);
      if (this.checklistData.length == 0) {
        this.addMore();
      }
    },

    removeDBBuilding(index,item){
      var msg = 'Are you sure want to remove this building record?';

      this.decisionAlert(msg)
      .then(result => {
          if (result.value) {
              return this.$store.dispatch(POST_API, {
                  data:{
                      building_id : item.building,
                      date        : this.date,
                      site        : this.site,
                      shift       : this.shift,
                  },
                  api : "/api/remove-mrd-db-building",
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;

                      this.showAlert();
                  } else {
                      //localStorage.setItem('routeCFCheck','no');
                      this.successAlert()
                      .then(() => {

                        this.checklistData.splice(index,1);
                        if (this.checklistData.length == 0) {
                          this.addMore();
                        }
                          
                      });
                  }
              });
          }
      })
      .catch(err => {
          console.log(err);
      })
    },
    
    getBuildings(){
        return this.$store.dispatch(POST_API, {
            data:{
                role:this.$store.getters.currentUser.role,
                site:this.site,
                form:'Meeting Room Defects'
            },
            api: '/api/all-buildings'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                this.buildings = this.$store.getters.getResults.data;
                return this.buildings;
            }
        });
    },
    openLocations(){
      this.$router.push({ name: 'hand-sanitizer-locations',params:{site_id:this.site != '' ? this.site : 'all-site',id:'all-building',form:'Meeting_Room_Defects'}})
    },
  breadCrumb(){
          if(this.$route.params.id){

        var item = [{
          to:{name:'client-dashboard'},
          text: 'Dashboard',
        },{
          to:null,
          text: 'Reports',
        },{
          to:{name:'custom-forms-dashboard'},
          text: 'Form Dashboard',
        },{
          to:{name:'custom-forms-reports'},
          text:'Custom Forms'
        },{
          to:{name:'meeting-room-defect-report'},
          text:'Meeting Room Defects Report'
        },{
          to:null,
          text:'Edit',
          active:true
        }];
        return this.filterReportBreadCrum(item);

      }else{
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:{name:'custom-forms'},
        text: 'Custom Forms',
      },{
        to:null,
        text:'Meeting Room Defects',   
        active:true             
      }];
      }
      return item;
    },
    disableFields(i,index,type){
      
      
      if (type == 'mr_quantity') {
        this.checklistData[i].data[index].mr_quantity = '';
        this.checklistData[i].data[index].mr_quantity_disable = this.checklistData[i].data[index].mr_quantity_disable == true ? false : true;  

      }else if(type == 'mr_yes'){
        this.checklistData[i].data[index].mr_yes = '';
        this.checklistData[i].data[index].mr_yes_disable = this.checklistData[i].data[index].mr_yes_disable == true ? false : true;

      }else if(type == 'mr_no'){
        this.checklistData[i].data[index].mr_no = '';
        this.checklistData[i].data[index].mr_no_disable = this.checklistData[i].data[index].mr_no_disable == true ? false : true;

      }else if(type == 'plastic'){
        this.checklistData[i].data[index].plastic ='';
        this.checklistData[i].data[index].plastic_disable = this.checklistData[i].data[index].plastic_disable == true ? false : true;

      }else if(type == 'sofa'){
        this.checklistData[i].data[index].sofa = '';
        this.checklistData[i].data[index].sofa_disable = this.checklistData[i].data[index].sofa_disable == true ? false : true;

      }else if(type == 'projector_on'){
        this.checklistData[i].data[index].projector_on = '';
        this.checklistData[i].data[index].projector_on_disable = this.checklistData[i].data[index].projector_on_disable == true ? false : true;

      }else if(type == 'projector_off'){
        this.checklistData[i].data[index].projector_off = '';
        this.checklistData[i].data[index].projector_off_disable = this.checklistData[i].data[index].projector_off_disable == true ? false : true;

      }

      return this.checklistData;
    },
    updateProjectorCheckbox(e,i,type,index){
      
      if (e == 'no') {
        this.checklistData[index].data[i].projector_on    = '';
        this.checklistData[index].data[i].projector_off    = '';
      }else{

        if (e == 'yes' && type == 'on') {

          this.checklistData[index].data[i].projector_off   = '';

        }else if(e == 'yes' && type == 'off'){

          this.checklistData[index].data[i].projector_on   = '';

        }
      }
    },
    disableCheckbox(i,type,index){

        
        if (type == 'on') {
          this.checklistData[index].data[i].projector_on = '';

          this.checklistData[index].data[i].projector_on_disable   = this.checklistData[index].data[i].projector_on_disable == true ? false : true;

        }else if(type == 'off'){
          this.checklistData[index].data[i].projector_off = '';

          this.checklistData[index].data[i].projector_off_disable   = this.checklistData[index].data[i].projector_off_disable == true ? false : true;

        }
    },

    disableLightDefects(i,index){

      this.checklistData[index].data[i].light_defects_disable   = this.checklistData[index].data[i].light_defects_disable == true ? false : true;


      if (this.checklistData[index].data[i].light_defects_disable == true) {

        //this.checklistData[i].data[index].mr_quantity = '';
        this.checklistData[index].data[i].light_defects = '';
        this.checklistData[index].data[i].mr_quantity_disable = true;
        this.checklistData[index].data[i].mr_yes = '';
        this.checklistData[index].data[i].mr_yes_disable = true;

        this.checklistData[index].data[i].mr_no = '';
        this.checklistData[index].data[i].mr_no_disable = true;
        
        this.checklistData[index].data[i].plastic = '';
        this.checklistData[index].data[i].plastic_disable = true;
        this.checklistData[index].data[i].sofa = '';
        this.checklistData[index].data[i].sofa_disable = true;

        this.checklistData[index].data[i].projector_on = '';
        this.checklistData[index].data[i].projector_on_disable = true;
        this.checklistData[index].data[i].projector_off = '';
        this.checklistData[index].data[i].projector_off_disable = true;
      }else{
        //this.checklistData[index].data[i].mr_quantity = '';
        this.checklistData[index].data[i].light_defects = '';
        this.checklistData[index].data[i].mr_quantity_disable = false;
        this.checklistData[index].data[i].mr_yes = '';
        this.checklistData[index].data[i].mr_yes_disable = false;

        this.checklistData[index].data[i].mr_no = '';
        this.checklistData[index].data[i].mr_no_disable = false;
        
        this.checklistData[index].data[i].plastic = '';
        this.checklistData[index].data[i].plastic_disable = false;
        this.checklistData[index].data[i].sofa = '';
        this.checklistData[index].data[i].sofa_disable = false;

        this.checklistData[index].data[i].projector_on = '';
        this.checklistData[index].data[i].projector_on_disable = false;
        this.checklistData[index].data[i].projector_off = '';
        this.checklistData[index].data[i].projector_off_disable = false;
      }
    },
    supervisorImageUpload(event){
        var input = event.target;
        var files = event.target.files

        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.readAsDataURL(input.files[0]);
            var image = input.files[0];

            if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'Please upload .jpg, .jpeg, .jpg images only',
                    showConfirmButton: false,
                    timer: 1500
                });

                this.$refs.supervisorSInput.value=null;

            } else if (image.size > 2097152) {
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'Maximum 2 MB files allowed to be upload.',
                    showConfirmButton: false,
                    timer: 1500
                })
                this.$refs.supervisorSInput.value=null;
            } else {
                reader.onload = (e) => {
                    this.signature.image = e.target.result;
                    this.signature.name = image.name;
                    this.signature.default = 'no';
                }
            }
        }
    },
      
    removeSign(sign){
        this.signature.image = null;
        this.signature.name = '';
        this.signature.default = 'no';
    },
    useDefault(){

      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$store.getters.currentUser._id
           },
           api: '/api/get-default-signature'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                if (data == null || data.default_signature == null) {
                  
                  this.having_default = false;
                  
                }else{

                  this.tempSign = {
                    image : data.default_signature,
                    name : 'siganture.png',
                  }

                  this.having_default = true;
                }
            }
        });
    },
    uploadSign(){
      
      this.$refs['supervisorSInput'].click();
      this.$refs['signatureModel'].hide();

    },
    openSignature(type){

      this.approve_signature_time = moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY HH:mm');
      if (this.openedSignModel == null) {

          if (this.having_default == true) {
              this.show_default = true;
          }else{
            this.show_default = false;
          }

          this.$refs['signatureModel'].show();
          this.openedSignModel = type;
      }
    },
    hideSignature(){
      this.openedSignModel = null;
    },
    clearSignature(){
      this.$refs.signaturePad.clearSignature();
    },
    saveSignature(){
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      
      if (isEmpty) {

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Signature is required',
          showConfirmButton: false,
          timer: 1500
        })

      }else{
        
        if (this.openedSignModel == 'supervisor') {

            this.signature.image = data;
            this.signature.name = 'signature.png';
            this.signature.type = 'supervisor';
            this.signature.default = 'no';

            this.$refs['signatureModel'].hide();
        }else{
            this.supervisorSign.image = data;
            this.supervisorSign.name = 'signature.png';
            this.supervisorSign.type = 'supervisor';
            this.supervisorSign.default = 'no';
            this.status = 'approved';
            this.actualForm();
        }
      }
    },
    saveDefault(){

      if (this.openedSignModel == 'supervisor') {
          this.signature = {
            image : this.tempSign.image,
            name : 'signature.png',
            default:'yes'
          }
        }else{

          this.supervisorSign = {
            image : this.tempSign.image,
            name : 'signature.png',
            default:'yes',
            type:'supervisor'
          }
          this.status = 'approved';
          this.actualForm();

        }
                 
      
        
      this.$refs['signatureModel'].hide();
    },
    discardForm(){
        var msg = 'Are you sure want to discard this form?';

        this.decisionAlert(msg)
        .then(result => {
            if (result.value) {
                return this.$store.dispatch(POST_API, {
                    data:{
                        id     : this.id,
                        status : 'deleted'
                    },
                    api : "/api/change-status-mrd",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.showAlert();
                    } else {
                        /*this.successAlert()
                        .then(() => {
                            this.siteChange();
                        });*/
                        localStorage.setItem('routeCFCheck','no');
                        this.successAlert()
                        .then(() => {
                            this.$router.go(-1);
                            this.$router.push({ name:'custom-forms' })
                        });
                    }
                });
            }
        })
        .catch(err => {
            console.log(err);
        })
    },
    updateRadio(i,index){
      
      //console.log(i,index);

      if (this.checklistData[i].data[index].light_defects == false) {
        this.checklistData[i].data[index].light_defects = '';
      }
      
      this.checklistData[i].data[index].light_defect_qty = ''
      this.checklistData[i].data[index].light_defects_custom = ''
      
      if (this.checklistData[i].data[index].light_defects == 'na' || this.checklistData[i].data[index].light_defects == 'custom') {

        //this.checklistData[i].data[index].mr_quantity = '';
        this.checklistData[i].data[index].mr_quantity_disable = true;
        this.checklistData[i].data[index].mr_yes = '';
        this.checklistData[i].data[index].mr_yes_disable = true;

        this.checklistData[i].data[index].mr_no = '';
        this.checklistData[i].data[index].mr_no_disable = true;
        
        this.checklistData[i].data[index].plastic = '';
        this.checklistData[i].data[index].plastic_disable = true;
        this.checklistData[i].data[index].sofa = '';
        this.checklistData[i].data[index].sofa_disable = true;

        //this.checklistData[i].data[index].projector_on = '';
        //this.checklistData[i].data[index].projector_on_disable = true;
        //this.checklistData[i].data[index].projector_off = '';
        //this.checklistData[i].data[index].projector_off_disable = true;
      }else{
        //this.checklistData[i].data[index].mr_quantity = '';
        this.checklistData[i].data[index].mr_quantity_disable = false;
        this.checklistData[i].data[index].mr_yes = '';
        this.checklistData[i].data[index].mr_yes_disable = false;

        this.checklistData[i].data[index].mr_no = '';
        this.checklistData[i].data[index].mr_no_disable = false;
        
        this.checklistData[i].data[index].plastic = '';
        this.checklistData[i].data[index].plastic_disable = false;
        this.checklistData[i].data[index].sofa = '';
        this.checklistData[i].data[index].sofa_disable = false;

        //this.checklistData[i].data[index].projector_on = '';
        //this.checklistData[i].data[index].projector_on_disable = false;
        //this.checklistData[i].data[index].projector_off = '';
        //this.checklistData[i].data[index].projector_off_disable = false;
      }
    },
    buildingDisable(id){
      var building_ids = [];

    
      for (var i = 0; i < this.checklistData.length; i++) {
        if (this.checklistData[i].building != '') {
          building_ids.push(this.checklistData[i].building);
        }
      }


      if (building_ids.indexOf(id) >= 0) {
        return true;
      }else{
        return false;
      }
    },
    goBack(){

      var msg = 'Do you want to Go back without saving your report?';

      this.decisionAlert(msg)
      .then(result => {
          
        if (result.value) {
          localStorage.setItem('routeCFCheck','no')
          // this.$router.go(-1);
          if(this.$route.params.id){
                 this.$router.push({name:'meeting-room-defect-report'})
          }else{
            this.$router.push({ name:'custom-forms' })
          }
        } 

          
      })
    },
    	getFormDetails(){
      /*localStorage.setItem('routeCFCheck','yes');*/

      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$route.params.id
           },
           api: '/api/get-mrd-report-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.id  = data._id;
                this.date  = data.checked_date;
                this.site  = data.site;
                this.checked_by  = data.checked_by;
                this.shift  = data.shift;
                this.checklistData  = data.checkListData;
                this.status = data.status;
                this.signature = {
                    image   : data.crew_signature,
                    name    : 'signature.png',
                    type    : 'supervisor',
                    default : 'yes'
                };
                this.startTime = data.startTime;
                this.endTime = data.endTime;

                var role = this.$store.getters.currentUser.role;

                if (data.reject_to == role && ((data.level_one_user_role != role) && (data.level_two_user_role != role) && (data.level_three_user_role != role))) {
                    //this.disabledOptions = false;
                    this.ledit = true;
                } else if(((role == 'admin') || (role == 'administrator')) && ( (data.level_user_status != null) && (data.level_user_status.role == ''))){
                    this.ledit = true;
                }

                this.getShiftData();
                this.getBuildings();
            }
        });

    },
    disabledDate(date){
      return date < moment(new Date(this.date)).subtract(1,'days').tz('Asia/Singapore');
    },
    checkdisabled(){
      if (this.ledit == true) {
        return false;
      }else if(this.disabledOptions == true){
        return true;
      }else{
        return false;
      }
    }
  },
  mounted(){
    this.useDefault();
    this.allSites();
    this.siteChange();
    //this.getLocationData();
    // to add crew sign
    Bus.$on('update-image', (data) => {
        this.checklistData[data.tindex].data[data.index].supervisor_signature = data.data;
    })

    // to delete crew sign
    Bus.$on('remove-image', (data,index) => {
        this.checklistData[index].data[data].supervisor_signature = {
            default:'no',
            name:'',
            image:null,
        };
    })
    		if(this.$route.params.id){
      		this.disabledOptions = true;
      		this.getFormDetails();
    	}
  }
  
  
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
